<template>
    <mobile-admin-collapse-tab title="Inscriptions" :badge="getBadgeOrNull('utilisateurs')">
        <p v-if="!newUsers.length">Aucune demande pour l'instant</p>
        <div v-for="(user, index) in newUsers" :key="'user_' + user.id">
            <hr v-if="index"/>
            <p class="text-center">
                <strong>{{ textUsername(user) }}</strong>
            </p>
            <p>
                <em>Association(s) :</em> {{ user.associations.map(({nom}) => nom).join(', ') }} <br/>
                <em>Date d'inscription :</em> {{ formatDate(user.dateInscription) }}
            </p>
            <p class="text-center">
                <b-button variant="success" pill @click="confirmSignup(user, true)">
                    <font-awesome-icon icon="check"/>
                </b-button>
                <b-button variant="danger" pill @click="confirmSignup(user, false)">
                    <font-awesome-icon icon="times"/>
                </b-button>
            </p>
        </div>
    </mobile-admin-collapse-tab>
</template>

<script>
    import {mapGetters} from "vuex";

    const AdminUserValidate = () => import('@/components/modals/AdminUserValidate');
    const MobileAdminCollapseTab = () => import('./MobileAdminCollapseTab');

    import http                        from "@/util/http";
    import alert                       from "@/util/alert";
    import {momentDates, textUsername} from '@/util/user';
    import {formatDate}                from '@/util/date';

    export default {
        name: "MobileAdminUserTab",
        components: {MobileAdminCollapseTab},
        data: () => ({
            newUsers: []
        }),
        computed: {
            ...mapGetters({getBadgeOrNull: ['badge/getBadgeOrNull']})
        },
        methods: {
            formatDate,
            textUsername,
            loadData() {
                alert.loading()
                    .then(() => this.axios.get(http.apiPath('admin_user_waiting_list')))
                    .then(response => this.newUsers = momentDates(response.data))
                    .catch(() => this.$toaster.error("Erreur : la liste des utilisateurs n'a pas pu être récupérée"))
                    .finally(alert.stopLoading);
            },
            confirmSignup(user, accept) {
                this.$store.dispatch('modal/create', {
                    component: AdminUserValidate,
                    props: {user: user, valid: accept, callback: () => this.loadData()}
                });
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>

<style scoped>
    em {
        text-decoration: underline;
    }
</style>